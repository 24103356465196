import { MutationTree } from "vuex";
import {
  Flow,
  Form,
  State,
  FormSetCurrentFlow,
  FormSetFlow,
  FormSetFlowStep,
  FormSetFlowElements,
  FormSetCurrentFlowElement,
  FormSetFlowElement,
  FormSetFlowElementRequired,
  FormSetFlowElementMetadata,
  FormSetFlowElementOptions,
  FormRemoveFlowElementOption,
  FormSetSettings,
  FormSetContent,
  FormSetFlowElementName,
  FormSetFlowElementAutoTemplateRender,
  FormSetFlowElementMultiple,
  FormRemoveFlowElement,
  FormSetFlowElementDependsOn,
  FormMoveFlowElement,
  FormSetTitle,
  FormSetInternalTitle,
  FormSetActive,
  FormRemoveOpportunity,
  FormSetOpportunity,
  FormSetMapping,
  FormSetFormulaMapping,
  FormRemoveFormulaMapping,
  FormRemoveMapping,
  FormSetMetadata,
  FormSetProtocol,
  FormRemoveProtocol,
  FormSetProtocols,
  FormSetFlows,
  FormRemoveFlow,
  FormAddFlow,
  FormSetFlowElementOption,
} from "./state";
import { MutationTypes } from "./types";

function formGuard(
  state: State,
  id: string | undefined,
  checkState = true
): string {
  if (!id) {
    throw new Error("A form id must be provided");
  }
  if (checkState) {
    if (!Object.keys(state).includes(id)) {
      throw new Error("The provided form id does not exist");
    }
  }
  return id;
}

function flowGuard(
  state: State,
  formUUID: string,
  id: string | undefined,
  checkState = true
): string {
  if (!id) {
    throw new Error("A flow id must be provided");
  }
  if (checkState) {
    if (state[formUUID].flows.findIndex((x) => x.id === id) === -1) {
      throw new Error("The provided flow id does not exist");
    }
  }
  return id;
}

function mappingGuard(
  state: State,
  formUUID: string,
  opportunityId: string,
  checkState = true
): string {
  formGuard(state, formUUID);
  if (!opportunityId) {
    throw new Error("A mapping opportunityId must be provided");
  }
  if (checkState) {
    if (
      !Object.values(state[formUUID].mapping)
        .map((x) => x.opportunityId)
        .includes(opportunityId)
    ) {
      throw new Error("The provided mapping opportunityId does not exist");
    }
  }
  return opportunityId;
}

function protocolGuard(
  state: State,
  formUUID: string,
  opportunityId: string,
  id: string,
  checkState = true
): string {
  formGuard(state, formUUID);
  if (!id) {
    throw new Error("A protocol id must be provided");
  }
  if (checkState) {
    if (
      !Object.values(state[formUUID].protocol[opportunityId] || [])
        .map((x) => x.id)
        .includes(id)
    ) {
      throw new Error("The provided protocol uuid does not exist");
    }
  }
  return id;
}

// Mutation Types
export type Mutations<S = State> = {
  [MutationTypes.FORM_SET_FORM](state: S, payload: Form): void;
  [MutationTypes.FORM_ADD_FLOW](state: S, payload: FormAddFlow): void;
  [MutationTypes.FORM_REMOVE_FLOW](state: S, payload: FormRemoveFlow): void;
  [MutationTypes.FORM_SET_FLOWS](state: S, payload: FormSetFlows): void;
  [MutationTypes.FORM_SET_CURRENT_FLOW](
    state: S,
    payload: FormSetCurrentFlow
  ): void;
  [MutationTypes.FORM_SET_FLOW](state: S, payload: FormSetFlow): void;
  [MutationTypes.FORM_SET_FLOW_STEP](state: S, payload: FormSetFlowStep): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENTS](
    state: S,
    payload: FormSetFlowElements
  ): void;
  [MutationTypes.FORM_SET_CURRENT_FLOW_ELEMENT](
    state: S,
    payload: FormSetCurrentFlowElement
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT](
    state: S,
    payload: FormSetFlowElement
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT_REQUIRED](
    state: S,
    payload: FormSetFlowElementRequired
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT_MULTIPLE](
    state: S,
    payload: FormSetFlowElementMultiple
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER](
    state: S,
    payload: FormSetFlowElementAutoTemplateRender
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT_NAME](
    state: S,
    payload: FormSetFlowElementName
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT_METADATA](
    state: S,
    payload: FormSetFlowElementMetadata
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT_OPTIONS](
    state: S,
    payload: FormSetFlowElementOptions
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT_OPTION](
    state: S,
    payload: FormSetFlowElementOption
  ): void;
  [MutationTypes.FORM_SET_FLOW_ELEMENT_DEPENDS_ON](
    state: S,
    payload: FormSetFlowElementDependsOn
  ): void;
  [MutationTypes.FORM_REMOVE_FLOW_ELEMENT_OPTION](
    state: S,
    payload: FormRemoveFlowElementOption
  ): void;
  [MutationTypes.FORM_REMOVE_FLOW_ELEMENT](
    state: S,
    payload: FormRemoveFlowElement
  ): void;
  [MutationTypes.FORM_MOVE_FLOW_ELEMENT](
    state: S,
    payload: FormMoveFlowElement
  ): void;
  [MutationTypes.FORM_SET_SETTINGS](state: S, payload: FormSetSettings): void;
  [MutationTypes.FORM_SET_CONTENT](state: S, payload: FormSetContent): void;
  [MutationTypes.FORM_SET_TITLE](state: S, payload: FormSetTitle): void;
  [MutationTypes.FORM_SET_INTERNAL_TITLE](
    state: S,
    payload: FormSetInternalTitle
  ): void;
  [MutationTypes.FORM_SET_ACTIVE](state: S, payload: FormSetActive): void;
  [MutationTypes.FORM_SET_OPPORTUNITY](
    state: S,
    payload: FormSetOpportunity
  ): void;
  [MutationTypes.FORM_REMOVE_OPPORTUNITY](
    state: S,
    payload: FormRemoveOpportunity
  ): void;
  [MutationTypes.FORM_SET_MAPPING](state: S, payload: FormSetMapping): void;
  [MutationTypes.FORM_SET_FORMULA_MAPPING](
    state: S,
    payload: FormSetFormulaMapping
  ): void;
  [MutationTypes.FORM_REMOVE_FORMULA_MAPPING](
    state: S,
    payload: FormRemoveFormulaMapping
  ): void;
  [MutationTypes.FORM_REMOVE_MAPPING](
    state: S,
    payload: FormRemoveMapping
  ): void;
  [MutationTypes.FORM_SET_METADATA](state: S, payload: FormSetMetadata): void;
  [MutationTypes.FORM_SET_PROTOCOL](state: S, payload: FormSetProtocol): void;
  [MutationTypes.FORM_REMOVE_PROTOCOL](
    state: S,
    payload: FormRemoveProtocol
  ): void;
  [MutationTypes.FORM_SET_PROTOCOLS](state: S, payload: FormSetProtocols): void;
};

// define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.FORM_SET_FORM](state: State, payload: Form) {
    state[payload.id] = payload;
  },
  [MutationTypes.FORM_ADD_FLOW](state: State, payload: FormAddFlow) {
    const formUUID = formGuard(state, payload.formUUID);
    flowGuard(state, formUUID, payload.flow.id, false);
    state[formUUID].flows.push(payload.flow);
  },
  [MutationTypes.FORM_REMOVE_FLOW](state: State, payload: FormRemoveFlow) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const index = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (index > -1) {
      state[formUUID].flows.splice(index, 1);
    }
  },
  [MutationTypes.FORM_SET_FLOWS](state: State, payload: FormSetFlows) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].flows = payload.flows;
  },
  [MutationTypes.FORM_SET_CURRENT_FLOW](
    state: State,
    payload: FormSetCurrentFlow
  ) {
    state[payload.formUUID].currentFlowUUID = payload.flowUUID;
  },
  [MutationTypes.FORM_SET_FLOW](state: State, payload: FormSetFlow) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flow.id);
    const index = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (index > -1) {
      state[payload.formUUID].flows[index] = { ...(payload.flow as Flow) };
    }
  },
  [MutationTypes.FORM_SET_FLOW_STEP](state: State, payload: FormSetFlowStep) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const index = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (index > -1) {
      state[payload.formUUID].flows[index].step = payload.step;
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENTS](
    state: State,
    payload: FormSetFlowElements
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const index = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (index > -1) {
      state[payload.formUUID].flows[index].elements = payload.elements;
    }
  },
  [MutationTypes.FORM_SET_CURRENT_FLOW_ELEMENT](
    state: State,
    payload: FormSetCurrentFlowElement
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].currentElementUUID = payload.elementUUID;
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT](
    state: State,
    payload: FormSetFlowElement
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.element.id);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[elementIndex] =
          payload.element;
      }
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT_REQUIRED](
    state: State,
    payload: FormSetFlowElementRequired
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[
          elementIndex
        ].required = payload.required === "true";
      }
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT_MULTIPLE](
    state: State,
    payload: FormSetFlowElementMultiple
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[
          elementIndex
        ].multiple = payload.multiple === "true";
      }
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT_AUTO_TEMPLATE_RENDER](
    state: State,
    payload: FormSetFlowElementAutoTemplateRender
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[
          elementIndex
        ].autoTemplateRender = payload.autoTemplateRender === "true";
      }
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT_NAME](
    state: State,
    payload: FormSetFlowElementName
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[elementIndex].name =
          payload.name;
      }
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT_METADATA](
    state: State,
    payload: FormSetFlowElementMetadata
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[
          elementIndex
        ].metadata = payload.metadata;
      }
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT_OPTIONS](
    state: State,
    payload: FormSetFlowElementOptions
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[
          elementIndex
        ].translation[state[payload.formUUID].locale].options = payload.options;
      }
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT_OPTION](
    state: State,
    payload: FormSetFlowElementOption
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[
          elementIndex
        ].translation[state[payload.formUUID].locale].options?.push(
          payload.option
        );
      }
    }
  },
  [MutationTypes.FORM_SET_FLOW_ELEMENT_DEPENDS_ON](
    state: State,
    payload: FormSetFlowElementDependsOn
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements[
          elementIndex
        ].dependsOn = payload.dependsOn;
      }
    }
  },
  [MutationTypes.FORM_REMOVE_FLOW_ELEMENT_OPTION](
    state: State,
    payload: FormRemoveFlowElementOption
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        const optionIndex = state[payload.formUUID].flows[flowIndex].elements[
          elementIndex
        ].translation[state[payload.formUUID].locale].options?.findIndex(
          (x) => x.value === payload.option
        );

        if (Number(optionIndex) > -1) {
          state[payload.formUUID].flows[flowIndex].elements[
            elementIndex
          ].translation[state[payload.formUUID].locale].options?.splice(
            Number(optionIndex),
            1
          );
        }
      }
    }
  },
  [MutationTypes.FORM_REMOVE_FLOW_ELEMENT](
    state: State,
    payload: FormRemoveFlowElement
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const flowUUID = flowGuard(state, formUUID, payload.flowUUID);
    const flowIndex = state[formUUID].flows.findIndex((x) => x.id === flowUUID);
    if (flowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        flowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[flowIndex].elements?.splice(
          Number(elementIndex),
          1
        );
      }
    }
  },
  [MutationTypes.FORM_MOVE_FLOW_ELEMENT](
    state: State,
    payload: FormMoveFlowElement
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const currFlowUUID = flowGuard(state, formUUID, payload.currFlowUUID);
    const newFlowUUID = flowGuard(state, formUUID, payload.newFlowUUID);
    const currFlowIndex = state[formUUID].flows.findIndex(
      (x) => x.id === currFlowUUID
    );
    const newFlowIndex = state[formUUID].flows.findIndex(
      (x) => x.id === newFlowUUID
    );
    if (currFlowIndex > -1 && newFlowIndex > -1) {
      const elementIndex = state[payload.formUUID].flows[
        currFlowIndex
      ].elements.findIndex((x) => x.id === payload.elementUUID);
      if (elementIndex > -1) {
        state[payload.formUUID].flows[newFlowIndex].elements = [
          ...state[payload.formUUID].flows[newFlowIndex].elements,
          ...state[payload.formUUID].flows[currFlowIndex].elements?.splice(
            Number(elementIndex),
            1
          ),
        ];
      }
    }
  },
  [MutationTypes.FORM_SET_SETTINGS](state: State, payload: FormSetSettings) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].settings = payload.settings;
  },
  [MutationTypes.FORM_SET_CONTENT](state: State, payload: FormSetContent) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].translation[state[payload.formUUID].locale].content =
      payload.content;
  },
  [MutationTypes.FORM_SET_TITLE](state: State, payload: FormSetTitle) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].translation[state[payload.formUUID].locale].title =
      payload.title;
  },
  [MutationTypes.FORM_SET_INTERNAL_TITLE](
    state: State,
    payload: FormSetInternalTitle
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].translation[state[payload.formUUID].locale].internalTitle =
      payload.internalTitle;
  },
  [MutationTypes.FORM_SET_ACTIVE](state: State, payload: FormSetActive) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].active = payload.active;
  },
  [MutationTypes.FORM_SET_OPPORTUNITY](
    state: State,
    payload: FormSetOpportunity
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const index = state[formUUID].opportunities.findIndex(
      (x) => x.opportunityId === payload.opportunity.opportunityId
    );
    if (!payload.opportunity.opportunityId) {
      return;
    }
    state[formUUID].opportunities = [
      payload.opportunity,
      ...(state[formUUID].opportunities || []),
    ];
  },
  [MutationTypes.FORM_REMOVE_OPPORTUNITY](
    state: State,
    payload: FormRemoveOpportunity
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const index = state[formUUID].opportunities.findIndex(
      (x) => x.id === payload.formOpportunityUUID
    );
    if (
      index > -1 &&
      !Object.keys(state[formUUID].mapping).includes(
        state[formUUID].opportunities[index].opportunityId
      )
    ) {
      state[formUUID].opportunities = [
        ...state[formUUID].opportunities.slice(0, index),
        ...state[formUUID].opportunities.slice(index + 1),
      ];
    }
  },
  [MutationTypes.FORM_SET_MAPPING](state: State, payload: FormSetMapping) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].mapping[payload.mapping.opportunityId] = payload.mapping;
  },
  [MutationTypes.FORM_SET_FORMULA_MAPPING](
    state: State,
    payload: FormSetFormulaMapping
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const opportunityId = mappingGuard(
      state,
      payload.formUUID,
      payload.opportunityId
    );
    const mappingEntryIndex = Array.isArray(
      state[formUUID].mapping[opportunityId].entries
    )
      ? state[formUUID].mapping[opportunityId].entries.findIndex(
          (x) => x.id === payload.entry.id
        )
      : -1;
    if (mappingEntryIndex > -1) {
      state[formUUID].mapping[opportunityId].entries[mappingEntryIndex] = {
        ...payload.entry,
      };
    } else {
      state[formUUID].mapping[opportunityId].entries = [
        ...state[formUUID].mapping[opportunityId].entries,
        payload.entry,
      ];
    }
  },
  [MutationTypes.FORM_REMOVE_FORMULA_MAPPING](
    state: State,
    payload: FormRemoveFormulaMapping
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const opportunityId = mappingGuard(
      state,
      payload.formUUID,
      payload.opportunityId
    );
    const mappingEntryIndex = Array.isArray(
      state[formUUID].mapping[opportunityId].entries
    )
      ? state[formUUID].mapping[opportunityId].entries.findIndex(
          (x) => x.id === payload.formulaMappingUUID
        )
      : -1;
    if (mappingEntryIndex > -1) {
      state[formUUID].mapping[opportunityId].entries.splice(
        mappingEntryIndex,
        1
      );
    }
  },
  [MutationTypes.FORM_REMOVE_MAPPING](
    state: State,
    payload: FormRemoveMapping
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const opportunityId = mappingGuard(
      state,
      payload.formUUID,
      payload.opportunityId
    );
    if (opportunityId !== "default") {
      delete state[formUUID].mapping[opportunityId];
    }
  },
  [MutationTypes.FORM_SET_METADATA](state: State, payload: FormSetMetadata) {
    const formUUID = formGuard(state, payload.formUUID);
    state[formUUID].metadata = payload.metadata;
  },
  [MutationTypes.FORM_SET_PROTOCOL](state: State, payload: FormSetProtocol) {
    const formUUID = formGuard(state, payload.formUUID);
    if (
      Array.isArray(state[formUUID].protocol[payload.protocol.opportunityId])
    ) {
      const protocolIndex = state[formUUID].protocol[
        payload.protocol.opportunityId
      ].findIndex((x) => x.id === payload.protocol.id);

      if (protocolIndex > -1) {
        state[formUUID].protocol[payload.protocol.opportunityId][
          protocolIndex
        ] = payload.protocol;
      } else {
        state[formUUID].protocol[payload.protocol.opportunityId].push(
          payload.protocol
        );
      }
    } else {
      state[formUUID].protocol[payload.protocol.opportunityId] = [
        payload.protocol,
      ];
    }
  },
  [MutationTypes.FORM_REMOVE_PROTOCOL](
    state: State,
    payload: FormRemoveProtocol
  ) {
    const formUUID = formGuard(state, payload.formUUID);
    const protocolUUID = protocolGuard(
      state,
      payload.formUUID,
      payload.opportunityId,
      payload.protocolUUID
    );
    const protocolIndex = Object.values(
      state[formUUID].protocol[payload.opportunityId]
    ).findIndex((x) => x.id === protocolUUID);
    if (protocolIndex > -1) {
      state[formUUID].protocol[payload.opportunityId].splice(protocolIndex, 1);
    }
  },
  [MutationTypes.FORM_SET_PROTOCOLS](state: State, payload: FormSetProtocols) {
    const formUUID = formGuard(state, payload.formUUID);
    payload.protocols.forEach((protocol) => {
      if (Array.isArray(state[formUUID].protocol[protocol.opportunityId])) {
        const protocolIndex = state[formUUID].protocol[
          protocol.opportunityId
        ].findIndex((x) => x.id === protocol.id);

        if (protocolIndex > -1) {
          state[formUUID].protocol[protocol.opportunityId][protocolIndex] =
            protocol;
        } else {
          state[formUUID].protocol[protocol.opportunityId].push(protocol);
        }
      } else {
        state[formUUID].protocol[protocol.opportunityId] = [protocol];
      }
    });
  },
};
